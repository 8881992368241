import { useAction, useSearchParams, useSubmission } from '@solidjs/router';
import { Match, onMount, Show, Suspense, Switch } from 'solid-js';
import { ActivityIndicator, Button, Container, Heading, HorizontalRule, Link, Page, Picture } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { IconCalendar } from '@troon/icons/calendar';
import NotFound from '../../_/404';
import { SupportButton } from '../../../components/support';
import { GiftingAccessPurchaseStatus, gql, mutationAction, useMutation } from '../../../graphql';
import { Receipt } from '../checkout/_components/receipt';
import type { RouteDefinition } from '@solidjs/router';

const artificialTimeoutMs = 2_000;

export default function ConfirmationPage() {
	const [params] = useSearchParams<{ subscriptionId?: string; productId?: string }>();

	const confirmSubmissionAction = useMutation(confirmGift);
	const confirmSubscription = useAction(confirmSubmissionAction);
	const confirmation = useSubmission(confirmSubmissionAction);

	onMount(async () => {
		if (!params.subscriptionId) {
			return;
		}
		const data = new FormData();
		data.set('id', params.subscriptionId);
		setTimeout(async () => {
			await confirmSubscription(data);
		}, artificialTimeoutMs);
	});

	return (
		<Show when={params.subscriptionId && params.productId} fallback={<NotFound />}>
			<Page>
				<Container size="xsmall">
					<Suspense>
						<Switch
							fallback={<ActivityIndicator class="size-16 text-brand">Confirming Subscription</ActivityIndicator>}
						>
							<Match when={confirmation.result?.error}>
								{(error) => (
									<>
										<p>
											{error()
												.graphQLErrors.map(({ message }) => message)
												.join('')}
										</p>
										<SupportButton />
									</>
								)}
							</Match>
							<Match when={confirmation.result?.data?.confirmation}>
								{(data) => (
									<div class="flex flex-col gap-8">
										<div class="flex flex-col items-center gap-6 text-center">
											<Picture
												src="https://images.ctfassets.net/rdsy7xf5c8dt/3aB6T12TKk56gVwEdh11Gx/656d985e55d0777a2f5b7d78570e6d4d/access-gift-cards.png"
												width={478}
												height={364}
												sizes="30rem"
												class="w-72 ease-in animate-in slide-in-from-top anim-duration-500"
												alt=""
											/>
											<Heading as="h1" size="h2">
												Your gift has been purchased!
											</Heading>
											<p class="text-lg">
												We’ve sent a confirmation email to {data().purchaserEmail}. Your gift recipient will receive an
												email on {dayjs(data().deliverAt).format('MMMM D, YYYY')} with instructions on how to redeem the
												gift.
											</p>

											<Button as={Link} href="/">
												Back to Home
											</Button>
										</div>

										<div class="flex flex-col gap-4 rounded border border-neutral bg-neutral-100 p-6">
											<Heading as="h2" size="h5">
												Gift Recipient Details
											</Heading>

											<div class="flex flex-col gap-3">
												<p>
													<b class="font-medium">{data().recipientName}</b>
													<br />
													{data().recipientEmail}
												</p>
												<HorizontalRule />
												<p>{data().message ?? 'No message'}</p>
												<HorizontalRule />
												<p>
													<IconCalendar class="text-brand" /> Send on {dayjs(data().deliverAt).format('MMMM D, YYYY')}
												</p>
											</div>
										</div>

										<div class="flex flex-col gap-4 rounded border border-neutral p-6">
											<Heading as="h2" size="h5">
												Order Summary
											</Heading>

											<Receipt
												discount={data().discountAmount}
												subtotal={data().subtotalAmount}
												tax={data().taxAmount}
												total={data().totalAmount}
											/>
										</div>
									</div>
								)}
							</Match>
						</Switch>
					</Suspense>
				</Container>
			</Page>
		</Show>
	);
}

const confirmGiftMutation = gql(`mutation confirmAccessGift($id: String!) {
	confirmation: confirmAccessGiftPurchaseIntent(id: $id) {
  	status
  	purchaserEmail
  	recipientName
  	recipientEmail
  	message
   	totalAmount { displayValue, value, code, cents }
    subtotalAmount { displayValue, value }
    discountAmount { displayValue, value }
    taxAmount { displayValue, value }
  	deliverAt
	}
}`);

const confirmGift = mutationAction(confirmGiftMutation, {
	retry: {
		retryIf: (res) => {
			return res.data?.confirmation.status !== GiftingAccessPurchaseStatus.Completed;
		},
	},
});

export const route = { info: { nav: { appearance: 'minimal' } } } satisfies RouteDefinition;
